import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import { AppleButton, TwitterButton, GoogleButton, FacebookButton } from './serviceButtons';
import PromoWrapper from './PromoWrapper';
import SuccessMessage from './SuccessMessage';

const STAGE_SIGNIN_SOCIAL = 'signin-social';
const STAGE_SIGNIN_EMAIL = 'signin-email';
const STAGE_REGISTER_SOCIAL = 'register-social';
const STAGE_REGISTER_EMAIL = 'register-email';
const STAGE_REGISTER_SUCCESS = 'register-success';

const LoginModal = ({
  isOpen,
  showPromoWrapper,
  promoContent = {},
  onRequestClose,
  onLoginSuccess,
  isSocialAuthEnabled,
  defaultToRegister,
}) => {
  const initialLoginStage = isSocialAuthEnabled ? STAGE_SIGNIN_SOCIAL : STAGE_SIGNIN_EMAIL;
  const initialRegisterStage = isSocialAuthEnabled ? STAGE_REGISTER_SOCIAL : STAGE_REGISTER_EMAIL;
  const initialStage = defaultToRegister ? initialRegisterStage : initialLoginStage;

  const [flowStage, setFlowStage] = useState(initialStage);
  useEffect(() => {
    setFlowStage(initialStage);
  }, [isOpen]);

  useEffect(() => {
    setFlowStage(initialStage);
  }, [isSocialAuthEnabled]);

  const handleSignUpFormSuccess = () => setFlowStage(STAGE_REGISTER_SUCCESS);

  const createLinkHandler = toStage => evt => {
    evt.preventDefault();
    setFlowStage(toStage);
  };

  const socialButtons = (
    <>
      <AppleButton className="mb-2" />
      <GoogleButton className="mb-2" />
      <TwitterButton className="mb-2" />
      <FacebookButton className="mb-2" />
    </>
  );

  let titleText = 'Log in';
  let modalContent;

  switch (flowStage) {
    case STAGE_SIGNIN_SOCIAL:
      modalContent = (
        <div>
          {socialButtons}

          <div className="hairline-divider my-4"><div>or</div></div>

          <button
            type="button"
            className="btn-z primary lg w-100 justify-content-center mb-3"
            onClick={() => setFlowStage(STAGE_SIGNIN_EMAIL)}
          >
            Log in with email
          </button>

          <div className="text-tertiary text-center">
            <span>Don’t have an account? </span>
            <a
              href="#sign-up"
              className="td-underline-hover"
              onClick={createLinkHandler(STAGE_REGISTER_EMAIL)}
            >
              Create one for free
            </a>
          </div>
        </div>
      );
      break;
    case STAGE_SIGNIN_EMAIL:
      modalContent = (
        <>
          <LoginForm onSuccess={onLoginSuccess} />
          {isSocialAuthEnabled ? (
            <div className="text-tertiary text-center">
              <a
                href="#all-sign-in-options"
                className="reset-link d-flex align-items-center justify-content-center"
                onClick={createLinkHandler(STAGE_SIGNIN_SOCIAL)}
              >
                <Icon i="chevron-left" size="xs" />
                <span className="ms-2">All log in options</span>
              </a>
            </div>
          ) : (
            <div className="text-tertiary text-center">
              <span>Don’t have an account? </span>
              <a
                href="#sign-up"
                className="td-underline-hover"
                onClick={createLinkHandler(STAGE_REGISTER_EMAIL)}
              >
                Create one for free
              </a>
            </div>
          )}
        </>
      );
      break;
    case STAGE_REGISTER_SOCIAL:
      titleText = 'Join Zeitcaster Today';
      modalContent = (
        <div>
          {socialButtons}

          <div className="hairline-divider my-4"><div>or</div></div>

          <button
            type="button"
            className="btn-z primary lg w-100 justify-content-center mb-3"
            onClick={() => setFlowStage(STAGE_REGISTER_EMAIL)}
          >
            Sign up with email
          </button>

          <div className="text-tertiary text-center">
            <span>Already have an account? </span>
            <a
              href="#all-sign-in-options"
              className="td-underline-hover"
              onClick={createLinkHandler(initialLoginStage)}
            >
              Log in
            </a>
          </div>
        </div>
      );
      break;
    case STAGE_REGISTER_EMAIL:
      titleText = 'Join Zeitcaster Today';
      modalContent = (
        <>
          <SignUpForm onSuccess={handleSignUpFormSuccess} />
          {isSocialAuthEnabled ? (
            <div className="text-tertiary text-center">
              <a
                href="#all-sign-up-options"
                className="reset-link d-flex align-items-center justify-content-center"
                onClick={createLinkHandler(STAGE_REGISTER_SOCIAL)}
              >
                <Icon i="chevron-left" size="xs" />
                <span className="ms-2">All sign up options</span>
              </a>
            </div>
          ) : (
            <div className="text-tertiary text-center">
              <span>Already have an account? </span>
              <a
                href="#all-sign-in-options"
                className="td-underline-hover"
                onClick={createLinkHandler(initialLoginStage)}
              >
                Log in
              </a>
            </div>
          )}
        </>
      );
      break;
    case STAGE_REGISTER_SUCCESS:
      titleText = '';
      modalContent = <SuccessMessage />;
      break;
  }

  const isRegisterStage = [STAGE_REGISTER_SOCIAL, STAGE_REGISTER_EMAIL].includes(flowStage);
  const wrap = content => (showPromoWrapper && isRegisterStage) ? (
    <PromoWrapper {...promoContent} onLoginClick={() => setFlowStage(STAGE_SIGNIN_SOCIAL)}>
      {content}
    </PromoWrapper>
  ) : (
    <div className="p-3 p-sm-6">
      {content}
    </div>
  );

  return (
    <Modal interiorClassName={!showPromoWrapper ? 'z-modal-interior' : ''} isOpen={isOpen} onRequestClose={onRequestClose}>
      {wrap(
        <>
          {titleText && <h4 className="fw-black text-secondary text-center mb-4">{titleText}</h4>}
          {modalContent}
        </>,
      )}
    </Modal>
  );
};

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  isSocialAuthEnabled: PropTypes.bool,
  defaultToRegister: PropTypes.bool,
  showPromoWrapper: PropTypes.bool,
  promoContent: PropTypes.shape({
    bulletPoints: PropTypes.arrayOf(PropTypes.string),
    footerContent: PropTypes.node,
  }),
  onRequestClose: PropTypes.func,
  onLoginSuccess: PropTypes.func,
};

export default LoginModal;
